import {
    Box,
    Text,
    chakra,
    HStack,
  } from "@chakra-ui/react";
  import getSymbolFromCurrency from "currency-symbol-map";
  import moment from "moment";
  import "moment/min/locales";




export const WaveComponent = ({
    isPreviousWaveSoldOut,
    eventName,
    ticketPrice,
    noOfRmainingTickets,
    waveType,
    description,
    startDate,
    endDate,
    timezone,
    currency,
    text,
    ticketSelected,
    language,
    decreaseFunction,
    increaseFunction,
    noOfTickets,
    accentColor,
    limit,
  }) => {
    //start date
    let startFormatted;
    if (startDate) {
      startFormatted = new Date(startDate); // startDate is already in ISO format
    }

    //end date 
    let endFormatted;
    if (endDate) {
      endFormatted = new Date(endDate); // endDate is already in ISO format
    }

    //current date
    const now = new Date().toLocaleString("en-US", {
     // timeZone: timezone,
    });
    const currentDateInTimezone = new Date(now);
    console.log(currentDateInTimezone, "currentDateInTimezone");
    console.log(startFormatted, "startFormatted");
    if ((!isPreviousWaveSoldOut)||(startFormatted && startFormatted > currentDateInTimezone)) {
      return;
    }
    moment.locale(language);

    return (
      <div>
        {isPreviousWaveSoldOut ||
        (startFormatted && startFormatted < currentDateInTimezone) ? (
          <Box width={"100%"}>
            <HStack
              padding="0.94rem"
              width={"100%"}
              borderRadius={description ? "2.5rem" : "4.25rem"}
              border={"1px solid rgba(255, 255, 255, 0.10)"}
              borderColor={
                noOfRmainingTickets <= 0
                  ? "rgba(255, 255, 255, 0.05)"
                  : "rgba(255, 255, 255, 0.10)"
              }
              justifyContent="space-between"
              alignItems={description ? "flex-start" : "center"}
            >
              <Box
                display={"flex"}
                flexDir={"column"}
                alignItems={"flex-start"}
                justifyContent={"center"}
                gap={"0.62rem"}
                marginLeft={"15px"}
                width={"100%"}
                overflow={"hidden"}
              >
                <Text
                  fontSize="1.1875rem"
                  color={"rgba(255, 255, 255, 0.60)"}
                  className="gordita600"
                  lineHeight={"120%"}
                  letterSpacing={"-0.01188rem"}
                  overflow={"hidden"}
                  whiteSpace={"nowrap"}
                  textOverflow={"ellipsis"}
                  minW={"0"}
                  width={"100%"}
                >
                  {eventName}
                </Text>
                <Text
                  color={
                    noOfRmainingTickets <= 0
                      ? "rgba(255, 255, 255, 0.40)"
                      : "#FFFFFF"
                  }
                  fontSize="1.1875rem"
                  lineHeight={"110%"}
                  letterSpacing={"-0.01188rem"}
                  className="gordita700"
                >
                  {ticketPrice === 0
                    ? `${text}`
                    : `${getSymbolFromCurrency(currency)} ${ticketPrice}`}
                </Text>
                {description && (
                  <Text
                    color={"rgba(255, 255, 255, 0.60)"}
                    fontSize={"0.875rem"}
                    className="gordita400"
                  >
                    {description}
                  </Text>
                )}
              </Box>

              {startFormatted > currentDateInTimezone ? (
                <Box
                  bg={"rgba(255, 255, 255, 0.05)"}
                  borderRadius={"6.25rem"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  color={"rgba(255, 255, 255, 0.05)"}
                  w={"11.3125rem"}
                  h={"3.375rem"}
                  flexShrink={"0"}
                  textAlign={"center"}
                >
                  <Text
                    marginLeft={15}
                    fontSize={"1rem"}
                    color={"rgba(255, 255, 255, 0.60)"}
                    className="gordita700"
                    letterSpacing={"-0.01rem"}
                  >
                    Available on {moment(startFormatted).format("ddd, D MMM")}{" "}
                    at {moment(startFormatted).format("HH:mm")}
                  </Text>
                </Box>
              ) : endFormatted < currentDateInTimezone ? (
                <Box
                  bg={"rgba(255, 255, 255, 0.05)"}
                  borderRadius={"6.25rem"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  color={"rgba(255, 255, 255, 0.05)"}
                  w={"6.3125rem"}
                  h={"3.375rem"}
                  flexShrink={"0"}
                >
                  <Text
                    fontSize={"1rem"}
                    color={"rgba(255, 255, 255, 0.60)"}
                    className="gordita700"
                    letterSpacing={"-0.01rem"}
                  >
                    Sold Out
                  </Text>
                </Box>
              ) : noOfRmainingTickets > 0 ? (
                <HStack
                display="flex"
                gap="5px"
                alignItems="center"
                // w={"18rem"}
              >
                <chakra.button
                  border="1px"
                  borderColor="rgba(255, 255, 255, 0.20)"
                  m={0}
                  p={0}
                  w="54px"
                  h="54px"
                  borderRadius="50%"
                  display={ticketSelected.find(ticket => ticket.ticketId === waveType)?.quantity > 0 ? "flex" : "none"}
                  alignItems="center"
                  justifyContent="center"
                  color="#FFFFFF"
                  cursor="pointer"
                  onClick={() => decreaseFunction(waveType)}
                  disabled={ticketSelected.find(ticket => ticket.ticketId === waveType)?.quantity === 0}

                  _hover={{
                    borderColor: "#FFFFFF",
                  }}
                  _active={{
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                  }}
                  _disabled={{
                    color: "rgba(255, 255, 255, 0.40)",
                    borderColor: "rgba(255, 255, 255, 0.10)",
                    cursor: "not-allowed",
                  }}
                >
                  <span
                    style={{
                      fontSize: "2.5rem",
                      paddingTop: "4px",
                    }}
                  >
                    -
                  </span>
                </chakra.button>
                <chakra.span
                        color={
                          ticketSelected.find(ticket => ticket.ticketId === waveType)?.quantity > 0
                            ? accentColor
                            : "rgba(255, 255, 255, 0.60)"
                        }
                  fontSize={"1.4375rem"}
                  width={"2rem"}
                  height={"2rem"}
                  paddingTop={"3px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className="gordita600"
                >
            {ticketSelected.find(ticket => ticket.ticketId === waveType)?.quantity||0}

                </chakra.span>
                <chakra.button
                  border="1px"
                  borderColor="rgba(255, 255, 255, 0.20)"
                  m={0}
                  p={0}
                  w="54px"
                  h="54px"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="#FFFFFF"
                  cursor="pointer"
                  onClick={() => increaseFunction(waveType)}
                  disabled={
                    ticketSelected.find(ticket => ticket.ticketId === waveType)?.quantity >= noOfRmainingTickets ||
                    ticketSelected.find(ticket => ticket.ticketId === waveType)?.quantity >= limit || noOfTickets>=10
                  }
                  
                  _hover={{
                    borderColor: "#FFFFFF",
                  }}
                  _active={{
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                  }}
                  _disabled={{
                    color: "rgba(255, 255, 255, 0.40)",
                    borderColor: "rgba(255, 255, 255, 0.10)",
                    cursor: "not-allowed",
                  }}
                >
                  <span
                    style={{
                      fontSize: "1.75rem",
                      paddingTop: "6px",
                    }}
                  >
                    +
                  </span>
                </chakra.button>
              </HStack>

              ) : (
                <Box
                  bg={"rgba(255, 255, 255, 0.05)"}
                  borderRadius={"6.25rem"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  color={"rgba(255, 255, 255, 0.05)"}
                  w={"6.3125rem"}
                  h={"3.375rem"}
                  flexShrink={"0"}
                >
                  <Text
                    fontSize={"1rem"}
                    color={"rgba(255, 255, 255, 0.60)"}
                    className="gordita700"
                    letterSpacing={"-0.01rem"}
                  >
                    Sold Out
                  </Text>
                </Box>
              )}
            </HStack>
          </Box>
        ) : null}
      </div>
    );
  };