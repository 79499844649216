import { Box, Flex, Input, Text, useColorModeValue } from "@chakra-ui/react";
import { useRef, useEffect } from "react";
import { loadGoogleMaps } from './loadGoogleMaps'; // Adjust the path accordingly
import secrets from "../secrets";

const AutoComplete = ({ handleChange, label, values, maxW }) => {
  const textValue = useColorModeValue("black.100", "white.100");
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {};

  useEffect(() => {
    const initAutoComplete = () => {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );

      autoCompleteRef.current.addListener("place_changed", () => {
        const place = autoCompleteRef.current.getPlace();

        const address = place.formatted_address;
        const name = place.name;
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        let locValueToDisplay = "";
        
        if (name && address && !address.includes(name)) {
          locValueToDisplay = `${name}, ${address}`;
        } else {
          locValueToDisplay = address;
        }
        handleChange("location", locValueToDisplay);
        handleChange("locValueToDisplay", locValueToDisplay);
        handleChange("longitude", lng);
        handleChange("latitude", lat);
        let city = "";
        let country = "";

        for (let i = place.address_components.length - 1; i >= 0; i--) {
          const component = place.address_components[i];

          // Check for locality (city) type
          if (component.types.includes("locality")) {
            city = component.long_name;
          }

          // Check for country type
          if (component.types.includes("country")) {
            country = component.long_name;
          }

          // If both city and country are found, break out of the loop
          if (city && country) {
            break;
          }
        }

        console.log(city, "city");
        console.log(country, "country");
        console.log(lat, "lat");
        console.log(lng, "lng");

        handleChange("city", city);
        handleChange("country", country);
      });
    };

    loadGoogleMaps(secrets.googleMapApi) // Replace with your actual API key
      .then(initAutoComplete)
      .catch((err) => console.error("Error loading Google Maps script:", err));
  }, []);

  return (
    <Box w="100%">
      <Flex mb="8px" alignItems="center" gap={10}>
        <Text
          fontSize={"1.3125rem"}
          color={textValue}
          className="gordita600"
          fontWeight={600}
          textTransform={"capitalize"}
        >
          {label}
        </Text>
      </Flex>
      <Input
        value={values["location"]}
        name="location"
        w={maxW}
        type="text"
        h="56px"
        className="gordita400"
        borderRadius="0.625rem"
        bg="#000000"
        color="#FFFFFF"
        placeholder="Location"
        border="1px solid rgba(255, 255, 255, 0.20)"
        focusBorderColor="#FFFFFF"
        _hover={{
          borderColor: "#FFFFFF !important",
        }}
        _placeholder={{
          color: "rgba(255, 255, 255, 0.40)",
        }}
        onChange={(e) => handleChange("location", e.target.value)}
        ref={inputRef}
      />
    </Box>
  );
};

export default AutoComplete;
